import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function CustomSelect({
  selectedValue,
  items,
  setValue,
  labelFieldName,
  keyFieldName,
  label,
}) {
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">{label}</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectedValue?.[keyFieldName]}
        label={label && label}
        onChange={(e) => setValue(e.target.value)}
      >
        {items.map((item) => (
          <MenuItem value={item?.[keyFieldName]}>
            {item?.[labelFieldName]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
