import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Typography,
  Container,
  Grid,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { MultipleContainers } from "./dnd/Multiple-Containers-Sortable/MultipleContainers";
import Slide from "@mui/material/Slide";
import CustomSelect from "./CustomSelect";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = ({
  open,
  mode,
  handleClose,
  modules,
  customAction,
  handleModuleChange,
  currentModuleFields,
  setCurrentModuleFields,
  currentDatabaseFields,
  setCurrentDatabaseFields,
  syncDuration,
  setSyncDuration,
  setDeleteBackupData,
  handleSave,
  selectedModule,
  deleteBackupData,
  handleDeleteField,
  syncAllFields,
  unSelectAllFields,
  syncDurations,
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        zIndex: 1,
      }}
      TransitionComponent={Transition}
    >
      <Container
        maxWidth="xl"
        sx={{
          py: customAction ? 1 : 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Header */}
        {!customAction && (
          <Box sx={{ width: "100%", height: "auto" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1.5,
              }}
            >
              <Grid
                container
                spacing={2}
                style={{ maxWidth: "500px", width: "100%" }}
                alignItems={"center"}
              >
                <Grid item xs={5}>
                  <Typography>Module Name</Typography>
                </Grid>
                <Grid item xs={7}>
                  {!customAction && (
                    <Autocomplete
                      id="modules-select"
                      fullWidth
                      disabled={mode === "editMode"}
                      size="small"
                      options={modules}
                      autoHighlight
                      getOptionLabel={(option) =>
                        option.plural_label || option.module_name
                      }
                      onChange={handleModuleChange}
                      value={selectedModule}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>

              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
          </Box>
        )}

        {/* Main */}
        <Box
          sx={{
            width: "100%",
            height: "65%",
          }}
        >
          <MultipleContainers
            currentModuleFields={currentModuleFields}
            setCurrentModuleFields={setCurrentModuleFields}
            currentDatabaseFields={currentDatabaseFields}
            setCurrentDatabaseFields={setCurrentDatabaseFields}
            handleDeleteField={handleDeleteField}
            syncAllFields={syncAllFields}
            unSelectAllFields={unSelectAllFields}
          />
          {/* Footer */}
          <Box mt={2}>
            <Box>
              {!customAction && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Typography sx={{ fontSize: "14px" }}>
                    Sync Frequently:
                  </Typography>

                  <CustomSelect
                    selectedValue={syncDuration}
                    items={syncDurations}
                    setValue={(selectedValue) => {
                      const convertIntoHours = selectedValue / 60;
                      const label = Number.isInteger(convertIntoHours)
                        ? `${convertIntoHours} Hours`
                        : `${convertIntoHours * 60} Mins`;

                      setSyncDuration({
                        label: label,
                        total_in_minutes: selectedValue,
                      });
                    }}
                    labelFieldName={"label"}
                    keyFieldName={"total_in_minutes"}
                  />
                  {/* {syncDurations.map((currentSync) => (
                  <>
                    <Chip
                      label={currentSync.label}
                      sx={{
                        backgroundColor: `${
                          syncDuration?.total_in_minutes ===
                          currentSync?.total_in_minutes
                            ? "#B27310"
                            : "rgba(0, 0, 0, 0.08)"
                        }`,
                        color: `${
                          syncDuration?.total_in_minutes ===
                          currentSync?.total_in_minutes
                            ? "white"
                            : "black"
                        }`,
                        "&:hover": {
                          color: "black",
                        },
                      }}
                      onClick={() => setSyncDuration(currentSync)}
                    />
                  </>
                ))} */}
                </Box>
              )}

              <FormGroup
                onChange={(e) => setDeleteBackupData(e.target.checked)}
              >
                <FormControlLabel
                  control={<Checkbox checked={deleteBackupData} />}
                  label="Delete backup data after 30 days from mysql database?"
                />
              </FormGroup>
            </Box>

            <Divider />

            <DialogActions sx={{ p: 0, py: 1 }}>
              <Button
                onClick={() => handleSave()}
                autoFocus
                variant="contained"
                sx={{
                  backgroundColor: "#B27310",
                  boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                  "&:hover": {
                    backgroundColor: "#B27310",
                  },
                  textTransform: "capitalize",
                }}
              >
                Save Settings
              </Button>
            </DialogActions>
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

export default CustomDialog;
