import React, { useState, useEffect } from "react";
import axios from "axios";

import Backdrop from "@mui/material/Backdrop";
import {
  Box,
  Skeleton,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import CustomTimelineItem from "../Components/relatedListViewComponents/CustomTimelineItem";
import { Timeline } from "@mui/lab";
import CustomSkeletonComponent from "../Components/relatedListViewComponents/CustomSkeletonComponent";

const ZOHO = window.ZOHO;
const containerStyle = {
  width: "100%",
  height: "450px",
};

export const unFlattenObj = (data, seperator = ".") => {
  let result = {};
  for (let i in data) {
    let keys = i.split(seperator);
    keys.reduce((acc, value, index) => {
      return (
        acc[value] ||
        (acc[value] = isNaN(Number(keys[index + 1]))
          ? keys.length - 1 === index
            ? data[i]
            : {}
          : [])
      );
    }, result);
  }
  return result;
};

export default function RelatedList() {
  const [moduleInfo, setModuleInfo] = useState(null);
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [moduleFields, setModuleFields] = useState({});

  const [zohoApiKey, setZohoApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();
  const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [dbConnUrl, setDbConnUrl] = useState();

  const [modifiedRecordInfo, setModifiedRecordInfo] = useState(null);
  const [fieldsLabel, setFieldsLabel] = useState(null);
  const [unflattenFetchedRecords, setUnflattenFetchedRecords] = useState(null);
  const [recordResp, setRecordResp] = useState(null);

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        setModuleInfo(data);
        setZohoLoaded(data);
        ZOHO.CRM.UI.Resize({ height: "700", width: "1000" });
        const orgData = {
          apiKeys: [
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__API_KEY",
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Organization_ID",
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__ZAPI_Key",
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL",
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__DB_Connection_URL",
          ],
        };
        const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);

        setDataCenterUrl(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL"
          ]?.value
            ? orgVariables?.Success?.Content?.[
                process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL"
              ]?.value != "null"
              ? orgVariables?.Success?.Content?.[
                  process.env.REACT_APP_EXTENSION_IDENTIFIER +
                    "__Data_Center_URL"
                ]?.value
              : dataCenterUrl
            : dataCenterUrl
        );
        setZohoApiKey(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__API_KEY"
          ]?.value
        );
        setDbConnUrl(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__DB_Connection_URL"
          ]?.value
        );
        setOrgId(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Organization_ID"
          ]?.value
        );
        setZapiKey(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__ZAPI_Key"
          ]?.value
        );

        //get respective zoho fields
        ZOHO.CRM.META.getFields({ Entity: data.Entity }).then(function (data) {
          //process fields to get field label
          data.fields.forEach((field) => {
            setModuleFields((prev) => ({
              ...prev,
              [field.api_name]: {
                display_label: field.display_label,
                field_label: field.field_label,
              },
            }));
          });
        });

        setZohoLoaded(true);
        /**
         * Business Logic
         */
      });
      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);

  useEffect(() => {
    console.log({ zohoLoaded, orgId, zohoApiKey });
    if (zohoLoaded && orgId && zohoApiKey) {
      async function fetchRecord() {
        const orgid = orgId;
        const apikey = zohoApiKey;
        const connname = process.env.REACT_APP_EXTENSION_IDENTIFIER;
        const moduleName = moduleInfo.Entity;
        const recordId = `${moduleInfo.EntityId}`;

        /**
         * Business Logic
         */
        const record_response = await ZOHO.CRM.API.getRecord({
          Entity: moduleName,
          approved: "both",
          RecordID: recordId,
        });

        setRecordResp(record_response?.data?.[0]);

        const fetchRecordFromDb = await axios.request({
          url: `${process.env.REACT_APP_DBURL}/${moduleName}/${recordId}`,
          method: "GET",
          headers: {
            orgid: orgid, // Org ID
            apikey: apikey, // API KEy
            connname: connname, // Conn Name
            connectionUrl: dbConnUrl,
          },
        });

        const fieldsThatWeDontNeed = [
          "id",
          "Modified_Time",
          "Created_Time",
          "Last_Activity_Time",
          "Full_Name",
          "Currency",
          "Email_Opt_Out",
          "Exchange_Rate",
        ];
        console.log({ fetchRecordFromDb });
        const unflattenRecordFromDb = await fetchRecordFromDb.data.data.map(
          (current_value, index) => {
            return {
              ...current_value,
              record_response: unFlattenObj(
                typeof current_value?.record_response === "string"
                  ? JSON.parse(current_value?.record_response)
                  : current_value?.record_response
              ),
            };
          }
        );
        console.log({ unflattenRecordFromDb });
        setUnflattenFetchedRecords(unflattenRecordFromDb);

        const filtered_records = unflattenRecordFromDb.map(
          (current_value, index) => {
            const filtered_record_response = {};
            const next_value = unflattenRecordFromDb[index + 1];
            Object.keys(current_value?.record_response).forEach(
              (field_api_name, index) => {
                //if field_api_name includes $ then dont render

                //check field value of current and next are equal or not
                //if equal dont render
                //otherwise render the row
                if (
                  field_api_name.includes("$") ||
                  fieldsThatWeDontNeed.includes(field_api_name)
                ) {
                  return null;
                }

                if (
                  typeof current_value?.record_response[field_api_name] ===
                    "object" &&
                  current_value?.record_response[field_api_name]
                ) {
                  //check name is equal or not
                  if (
                    current_value?.record_response[field_api_name].name ===
                    next_value?.record_response[field_api_name].name
                  )
                    return null;
                }

                if (
                  current_value?.record_response[field_api_name] ===
                  next_value?.record_response[field_api_name]
                ) {
                  return null;
                }

                filtered_record_response[field_api_name] =
                  current_value?.record_response[field_api_name];
              }
            );
            return {
              ...current_value,
              record_response: filtered_record_response,
            };
          }
        );

        console.log({ filtered_records });
        setModifiedRecordInfo(filtered_records);
      }

      async function getFieldsLabel() {
        const getResponse = await ZOHO.CRM.META.getFields({
          Entity: moduleInfo.Entity,
        });
        const field_label_against_api_name = {};
        getResponse.fields.forEach((field) => {
          field_label_against_api_name[field.api_name] = field.display_label;
        });

        return field_label_against_api_name;
      }

      (async () => {
        try {
          const fieldsLabel = await getFieldsLabel();
          setFieldsLabel(fieldsLabel);
          if (dbConnUrl) {
            await fetchRecord();
          }
          setLoading(false);
        } catch (err) {
          console.log({ err });
        }
      })();
    }
  }, [zohoLoaded, zohoApiKey, orgId, dbConnUrl, moduleInfo]);
  console.log({ modifiedRecordInfo });

  if (loading || !modifiedRecordInfo || !recordResp) {
    return (
      <Box sx={{ width: "100%", height: "100%", p: "8px 24px" }}>
        <Skeleton
          variant="text"
          sx={{
            mb: "8px",
            fontSize: "45px",
            fontWeight: "500",
            ml: "16px",
            width: "140px",
          }}
        />

        <CustomSkeletonComponent />
        <CustomSkeletonComponent />
      </Box>
    );
  }

  if (modifiedRecordInfo && modifiedRecordInfo?.length <= 1) {
    return (
      <Box sx={{ width: "100%", height: "100%", p: "8px 24px", mt: 14 }}>
        <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
          No Database History Available
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", height: "100%", p: "8px 24px" }}>
      <Typography
        sx={{ mb: "8px", fontSize: "20px", fontWeight: "500", ml: "16px" }}
      >
        Record History
      </Typography>

      <Timeline
        sx={{
          "& .MuiTimeline-root": {
            padding: 0,
          },
          "& .MuiTimelineItem-root:before": {
            flex: 0,
            padding: "0px",
          },
          width: "100%",
        }}
      >
        {/* {JSON.stringify(modifiedRecordInfo)} */}
        {modifiedRecordInfo?.map((backupRecord, index) => {
          return (
            <CustomTimelineItem
              key={backupRecord?.id}
              current_value={backupRecord}
              next_value={unflattenFetchedRecords[index + 1]}
              fieldsLabel={fieldsLabel}
              latestValue={recordResp}
              record_id={moduleInfo.EntityId}
            />
          );
        })}
      </Timeline>
    </Box>
  );
}
