import React, { forwardRef } from "react";
import classNames from "classnames";

import { Handle, Remove } from "../Item";

import styles from "./Container.module.css";
import { Box, Button } from "@mui/material";

export const Container = forwardRef(
  (
    {
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      setSearchText,
      syncAllFields,
      unSelectAllFields,
      id,
      ...props
    },
    ref
  ) => {
    const Component = onClick ? "button" : "div";

    return (
      <Component
        {...props}
        ref={ref}
        style={{
          ...style,
          "--columns": columns,
        }}
        className={classNames(
          styles.Container,
          unstyled && styles.unstyled,
          horizontal && styles.horizontal,
          hover && styles.hover,
          placeholder && styles.placeholder,
          scrollable && styles.scrollable,
          shadow && styles.shadow
        )}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.Header}>{label}</div>
          {id === "zoho_fields" && (
            <Button
              onClick={syncAllFields}
              variant="contained"
              size="small"
              sx={{ width: "150px", textTransform: "capitalize" }}
            >
              Select All
            </Button>
          )}
          {id === "selected_fields" && (
            <Button
              onClick={unSelectAllFields}
              variant="contained"
              size="small"
              sx={{ width: "150px", textTransform: "capitalize" }}
            >
              Unselect All
            </Button>
          )}
        </Box>
        <ul className={styles.ContentBox}>{children}</ul>
      </Component>
    );
  }
);
