import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
  Container,
} from "@mui/material";

export default function Overview({
  //   handleRevokeGoogle,
  handleRevokeZoho,
  revokeLoadingZoho,
  //   revokeLoadingGoogle,
  authLoadingZoho,
  //   authLoadingGoogle,
  zohoAuth,
  //   googleAuth,
  handleAuthenticateZoho,
  //   handleAuthenticateGoogle,
}) {
  return (
    <Container maxWidth="xl" sx={{ py: 2 }}>
      <Box
        sx={{
          py: 1,
          width: "85%",
          mx: "auto",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          borderRadius: "10px",

          bgcolor: "#fff",
        }}
      >
        <Box sx={{ px: 4 }}>
          <Typography
            sx={{
              mt: 2,
              mb: 1,
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "24px",
              fontWeight: 600,
            }}
          >
            Authentication
          </Typography>
          <Divider />
          <br />

          <Box
            sx={{
              color: "rgba(0, 0, 0, 0.6)",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",

              "li:not(:last-child)": {
                margin: "0 0 10px 0",
              },
            }}
          >
            "Easy MySQL Sync" is a standalone app which needs to integrate with
            ZOHO using Rest API. To do Read/Create/Edit/Delete tasks in "Easy
            MySQL Sync" you need to authenticate ZOHO CRM to make
            Widgets/Workflow functional.
          </Box>
        </Box>
        <Box sx={{ ml: 7, mt: 3 }}>
          {/* {googleAuth ? (
          <Button
            disabled={revokeLoadingGoogle}
            onClick={handleRevokeGoogle}
            sx={{
              mt: 2.5,
              mb: 2,
              mr: 2,
              textTransform: "none",
              width: "14rem",
              borderRadius: "8px",
              height: "2.9rem",
              bgcolor: "#1976D2",
            }}
            variant="contained"
          >
            {revokeLoadingGoogle ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                loading{" "}
                <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
              </Box>
            ) : (
              "Rovoke Google Drive"
            )}
          </Button>
        ) : (
          <Button
            disabled={authLoadingGoogle}
            onClick={() => {
              handleAuthenticateGoogle();
            }}
            sx={{
              mt: 2.5,
              mb: 2,
              mr: 2,
              textTransform: "none",
              width: "14rem",
              borderRadius: "8px",
              height: "2.9rem",
              bgcolor: "#1976D2",
            }}
            variant="contained"
          >
            {authLoadingGoogle ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                Verifying{" "}
                <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
              </Box>
            ) : (
              "Authenticate Google Drive"
            )}
          </Button>
        )} */}

          {/* <Button
          disabled={authLoadingGoogle}
          onClick={() => {
            handleAuthenticateGoogle();
          }}
          sx={{
            // mt: 2.5,
            // mb: 2,
            // mr: 2,
            // textTransform: "none",
            // width: "14rem",
            // borderRadius: "8px",
            // height: "2.9rem",
            // bgcolor: "#1976D2",
          }}
          variant="contained"
        >
          Authenticate XYZ Service
        </Button> */}

          {zohoAuth ? (
            <Button
              disabled={revokeLoadingZoho}
              onClick={handleRevokeZoho}
              sx={{
                mt: 2.5,
                mb: 2,
                textTransform: "none",
                width: "14rem",
                borderRadius: "8px",
                height: "2.9rem",
                bgcolor: "#fc493d",
                "&:hover": { bgcolor: "#fa3023" },
              }}
              variant="contained"
            >
              {revokeLoadingZoho ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  loading
                  <CircularProgress
                    sx={{ color: "white", ml: 1 }}
                    size={17}
                  />{" "}
                </Box>
              ) : (
                "Revoke ZOHO CRM"
              )}
            </Button>
          ) : (
            <Button
              disabled={authLoadingZoho}
              onClick={() => {
                handleAuthenticateZoho();
              }}
              sx={{
                mt: 2.5,
                mb: 2,
                textTransform: "none",
                width: "14rem",
                borderRadius: "8px",
                height: "2.9rem",
                bgcolor: "#1976D2",
              }}
              variant="contained"
            >
              {authLoadingZoho ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Verifying{" "}
                  <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
                </Box>
              ) : (
                "Authenticate ZOHO CRM"
              )}
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
}
