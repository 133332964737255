import {
  Box,
  Skeleton,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";

const CustomSkeletonComponent = () => {
  return (
    <Box sx={{ width: "100%", height: "10rem", mb: "2.5rem" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "1.5rem",
          }}
        >
          <Skeleton variant="circular" width={10} height={10} />
          <Skeleton
            variant="text"
            sx={{
              fontSize: "30px",
              width: "200px",
            }}
          />
        </Box>

        <Skeleton
          variant="text"
          sx={{
            fontSize: "45px",
            width: "140px",
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "1.5rem",
        }}
      >
        <Skeleton variant="rectangular" width={5} height={120} />

        <Box sx={{ width: "100%", height: "100%", mt: "1rem", mb: "0.5rem" }}>
          <TableContainer
            sx={{ borderRadius: "8px", border: "1px solid #0000003B" }}
          >
            <Table
              sx={{ minWidth: 700, width: "100%" }}
              size="small"
              aria-label="skeleton table"
            >
              <TableRow
                sx={{
                  width: "100%",
                  // borderBottom: "1px solid #0000003B",
                  borderRadius: "5px 20px 50px 0",
                }}
              >
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  width: "100%",
                  // borderBottom: "1px solid #0000003B",
                  borderRadius: "5px 20px 50px 0",
                }}
              >
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  width: "100%",
                  // borderBottom: "1px solid #0000003B",
                  borderRadius: "5px 20px 50px 0",
                }}
              >
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomSkeletonComponent;
