import React, { useEffect } from "react";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { Handle } from "./components";
import styles from "./Item.module.css";

export const Item = React.memo(
  React.forwardRef(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        containerId,
        handleDeleteField,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = "grabbing";

        return () => {
          document.body.style.cursor = "";
        };
      }, [dragOverlay]);

      return renderItem ? (
        renderItem({
          dragOverlay,
          dragging,
          sorting,
          index,
          fadeIn,
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
        })
      ) : (
        <li
          className={classNames(
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay
          )}
          style={{
            ...wrapperStyle,
            transition: [transition, wrapperStyle?.transition]
              .filter(Boolean)
              .join(", "),
            "--translate-x": transform
              ? `${Math.round(transform.x)}px`
              : undefined,
            "--translate-y": transform
              ? `${Math.round(transform.y)}px`
              : undefined,
            "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
            "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
            "--index": index,
            "--color": color,
          }}
          ref={ref}
        >
          <div
            className={classNames(
              styles.Item,
              dragging && styles.dragging,
              handle && styles.withHandle,
              dragOverlay && styles.dragOverlay,
              disabled && styles.disabled,
              color && styles.color,
              containerId === "zoho_fields" && styles.FirstContainerBasedItem,
              containerId === "selected_fields" &&
                styles.SecondContainerBasedItem
            )}
            style={{
              ...style,
              background:
                !value.field_exist_in_zoho &&
                value.hasOwnProperty("field_exist_in_zoho")
                  ? "#fce5e6"
                  : "",
            }}
            data-cypress="draggable-item"
            {...(!value.field_exist_in_zoho &&
            value.hasOwnProperty("field_exist_in_zoho")
              ? null
              : listeners)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            {!value.field_exist_in_zoho &&
            value.hasOwnProperty("field_exist_in_zoho") ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",

                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Handle />
                  {value?.field_label}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title="This field doest exist in this module."
                    sx={{ fontSize: "16px" }}
                  >
                    <InfoIcon
                      sx={{
                        fontSize: "18px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                  <DeleteOutlineIcon
                    sx={{ fontSize: "21px", cursor: "pointer" }}
                    onClick={() => handleDeleteField(value)}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Handle {...handleProps} {...listeners} />
                {value?.field_label}
              </div>
            )}
          </div>
        </li>
      );
    }
  )
);
