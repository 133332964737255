import React, { forwardRef } from "react";

import { Action } from "../Action";

export const Handle = forwardRef((props, ref) => {
  return (
    <Action ref={ref} cursor="grab" data-cypress="draggable-handle" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9.16671 15C9.16671 15.9167 8.41671 16.6667 7.50004 16.6667C6.58337 16.6667 5.83337 15.9167 5.83337 15C5.83337 14.0833 6.58337 13.3333 7.50004 13.3333C8.41671 13.3333 9.16671 14.0833 9.16671 15ZM7.50004 8.33333C6.58337 8.33333 5.83337 9.08333 5.83337 10C5.83337 10.9167 6.58337 11.6667 7.50004 11.6667C8.41671 11.6667 9.16671 10.9167 9.16671 10C9.16671 9.08333 8.41671 8.33333 7.50004 8.33333ZM7.50004 3.33333C6.58337 3.33333 5.83337 4.08333 5.83337 5C5.83337 5.91667 6.58337 6.66667 7.50004 6.66667C8.41671 6.66667 9.16671 5.91667 9.16671 5C9.16671 4.08333 8.41671 3.33333 7.50004 3.33333ZM12.5 6.66667C13.4167 6.66667 14.1667 5.91667 14.1667 5C14.1667 4.08333 13.4167 3.33333 12.5 3.33333C11.5834 3.33333 10.8334 4.08333 10.8334 5C10.8334 5.91667 11.5834 6.66667 12.5 6.66667ZM12.5 8.33333C11.5834 8.33333 10.8334 9.08333 10.8334 10C10.8334 10.9167 11.5834 11.6667 12.5 11.6667C13.4167 11.6667 14.1667 10.9167 14.1667 10C14.1667 9.08333 13.4167 8.33333 12.5 8.33333ZM12.5 13.3333C11.5834 13.3333 10.8334 14.0833 10.8334 15C10.8334 15.9167 11.5834 16.6667 12.5 16.6667C13.4167 16.6667 14.1667 15.9167 14.1667 15C14.1667 14.0833 13.4167 13.3333 12.5 13.3333Z"
          fill="black"
          fill-opacity="0.54"
        />
      </svg>
    </Action>
  );
});
