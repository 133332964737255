import React, { useState } from "react";
import {
  TimelineItem,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
} from "@mui/lab";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  IconButton,
  Tooltip,
  TableContainer,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { isEqual } from "date-fns";

function isDisabledField({ next_value, latestValue, field_api_name }) {
  console.log({
    next_value: next_value,
    latestValue: latestValue,
    field_api_name,
    isEqual:
      next_value.record_response?.[field_api_name] ===
      latestValue[field_api_name],
  });
  // if (!next_value?.record_response) return false;
  if (
    typeof latestValue?.[field_api_name] === "object" &&
    typeof next_value.record_response?.[field_api_name] === "object"
  ) {
    return (
      next_value.record_response[field_api_name]?.id ===
      latestValue[field_api_name]?.id
    );
  }
  //Boolean

  if (
    typeof latestValue?.[field_api_name] === "boolean" &&
    typeof next_value.record_response?.[field_api_name] === "boolean"
  ) {
    return (
      String(next_value?.record_response[field_api_name]) ===
      String(latestValue[field_api_name])
    );
  }

  return (
    next_value.record_response?.[field_api_name] === latestValue[field_api_name]
  );
}

const ZOHO = window.ZOHO;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme, width }) => ({
  [`&.${tableCellClasses.root}`]: {
    width: width,
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(25, 118, 210, 0.08)",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: "500",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "white",
  // hide last border
  // "&:last-child td, &:last-child tr": {
  //   borderBottom: "0",
  //   borderRight: "0",
  //   borderLeft: "0",
  // },
}));

const CustomTimelineItem = ({
  current_value,
  next_value,
  fieldsLabel,
  latestValue,
  record_id,
}) => {
  const [state, setState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
    message: null,
    severity: null,
  });

  const formattedDateTime = (date) => {
    let splitDate = date.split("T");
    let splitTime = splitDate[1].split(".");

    return splitDate[0] + " " + splitTime[0];
  };

  const handleRestore = async (data) => {
    let config = {
      Entity: data?.module_name,
      APIData: { ...data.record_response, id: record_id },
      Trigger: ["workflow"],
    };

    try {
      const updateResp = await ZOHO.CRM.API.updateRecord(config);
      if (updateResp.data) {
        setState((prev) => ({
          ...prev,
          open: true,
          message: "Data Successfully Restored",
          severity: "success",
        }));
        setTimeout(() => {
          setState((prev) => ({
            ...prev,
            open: false,
          }));
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      setState((prev) => ({
        ...prev,
        open: true,
        message: err.message || err.data[0].message,
        severity: "error",
      }));
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          open: false,
        }));
      }, 2000);
    }
  };

  if (!next_value) return null;

  return (
    <TimelineItem sx={{ width: "100%", mb: "12px" }}>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            backgroundColor: "#0288D1",
          }}
        />
        <TimelineConnector
          sx={{
            backgroundColor: "#0288D1",
          }}
        />
      </TimelineSeparator>
      <TimelineContent
        sx={{
          width: "100%",
          "& .MuiTimelineContent-root": {
            padding: "0px 16px !important",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: "12px",
            mt: "-6px",
          }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: state.vertical,
              horizontal: state.horizontal,
            }}
            open={state.open}
            autoHideDuration={2000}
          >
            <Alert severity={state.severity} sx={{ width: "100%" }}>
              {state.message}
            </Alert>
          </Snackbar>

          <Typography>
            {formattedDateTime(current_value?.created_at)}
          </Typography>

          <Button
            startIcon={<HistoryIcon />}
            variant="outlined"
            onClick={() => {
              console.log({ next_value, current_value });

              handleRestore(next_value);
            }}
            sx={{ textTransform: "capitalize" }}
          >
            Restore
          </Button>
        </Box>

        <Box sx={{ width: "100%" }}>
          <TableContainer
            sx={{ borderRadius: "8px", border: "1px solid #0000003B" }}
          >
            <Table
              sx={{ minWidth: 700, width: "100%" }}
              size="small"
              aria-label="customized table"
            >
              <TableHead sx={{ backgroundColor: "#FAFAFA", width: "100%" }}>
                <TableRow
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid #0000003B",
                    borderRadius: "5px 20px 50px 0",
                  }}
                >
                  <StyledTableCell align="left">Field Name</StyledTableCell>
                  <StyledTableCell align="left">Updated From</StyledTableCell>
                  <StyledTableCell align="left">Updated To</StyledTableCell>
                  <StyledTableCell align="left">Current Value</StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={{ width: "100%" }}>
                {Object.keys(current_value?.record_response).map(
                  (field_api_name) => {
                    return (
                      fieldsLabel[field_api_name] &&
                      (next_value?.record_response[field_api_name] ||
                        current_value?.record_response[field_api_name]) && (
                        <StyledTableRow
                          key={field_api_name}
                          sx={{
                            width: "100%",
                            "&:hover .hide-button": {
                              visibility: "visible",
                            },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ width: "22.5%" }}
                          >
                            {fieldsLabel[field_api_name]}
                          </TableCell>
                          <TableCell align="left" sx={{ width: "22.5%" }}>
                            {(() => {
                              //object
                              if (
                                typeof next_value?.record_response[
                                  field_api_name
                                ] === "object" &&
                                next_value?.record_response[field_api_name]
                              ) {
                                return next_value?.record_response[
                                  field_api_name
                                ]?.name;
                              }
                              //Boolean

                              if (
                                typeof next_value?.record_response[
                                  field_api_name
                                ] === "boolean"
                              ) {
                                return String(
                                  next_value?.record_response[field_api_name]
                                );
                              }

                              return next_value?.record_response[
                                field_api_name
                              ];
                            })()}
                          </TableCell>
                          <TableCell align="left" sx={{ width: "22.5%" }}>
                            {(() => {
                              //object
                              if (
                                typeof current_value?.record_response[
                                  field_api_name
                                ] === "object" &&
                                current_value?.record_response[field_api_name]
                              ) {
                                return current_value?.record_response[
                                  field_api_name
                                ]?.name;
                              }
                              //Boolean

                              if (
                                typeof current_value?.record_response[
                                  field_api_name
                                ] === "boolean"
                              ) {
                                return String(
                                  current_value?.record_response[field_api_name]
                                );
                              }

                              return current_value?.record_response[
                                field_api_name
                              ];
                            })()}
                          </TableCell>
                          <TableCell align="left" sx={{ width: "22.5%" }}>
                            {(() => {
                              //object
                              if (
                                typeof latestValue?.[field_api_name] ===
                                "object"
                              ) {
                                return latestValue?.[field_api_name]?.name;
                              }
                              //Boolean

                              if (
                                typeof latestValue[field_api_name] === "boolean"
                              ) {
                                return String(latestValue[field_api_name]);
                              }

                              return latestValue[field_api_name];
                            })()}
                          </TableCell>
                          <TableCell align="left" sx={{ width: "10%" }}>
                            <Tooltip
                              title={
                                isDisabledField({
                                  next_value,
                                  latestValue,
                                  field_api_name,
                                })
                                  ? "Updated to value is already the same as the current CRM value"
                                  : ""
                              }
                              placement="bottom-end"
                            >
                              <span>
                                <IconButton
                                  className="hide-button"
                                  startIcon={<HistoryIcon />}
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    textTransform: "capitalize",
                                    visibility: "hidden",
                                    transition: "visibility 0.01s ease-in-out",
                                    "& .MuiButtonBase-root": {
                                      padding: "0 !important",
                                    },
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleRestore({
                                      module_name: next_value.module_name,
                                      record_response: {
                                        [field_api_name]:
                                          next_value.record_response[
                                            field_api_name
                                          ],
                                      },
                                    })
                                  }
                                  disabled={isDisabledField({
                                    next_value,
                                    latestValue,
                                    field_api_name,
                                  })}
                                >
                                  <HistoryIcon
                                    sx={{
                                      color: isDisabledField({
                                        next_value,
                                        latestValue,
                                        field_api_name,
                                      })
                                        ? "gray"
                                        : "#0288D1",
                                    }}
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </TableCell>
                        </StyledTableRow>
                      )
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

export default CustomTimelineItem;
