import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Backdrop,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

import CustomSnackbar from "./CustomSnackbar";
const ZOHO = window.ZOHO;

const DBConnection = ({
  setTabNumber,
  setDisabledTabs,
  orgId,
  apiKey,
  handleConnectionUrl,
}) => {
  const [showBackdrop, setShowBackDrop] = useState(false);
  const [validConnectionUrl, setValidConnectionUrl] = useState(false);
  const [loader, setLoader] = useState({
    test_conn: false,
    save_conn: false,
  });

  //snackbar state and functions
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState(""); // success or error
  const [settings, setSettings] = useState(null);

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      port: 3306,
    },
  });

  useEffect(() => {
    (async () => {
      const orgData = {
        apiKeys: [
          process.env.REACT_APP_EXTENSION_IDENTIFIER + "__DB_Connection_URL",
        ],
      };

      const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);

      let connectionUrl = orgVariables?.Success?.Content;

      if (!connectionUrl || connectionUrl === "null") {
        return;
      }

      setShowBackDrop(true);

      try {
        const getAllModulesData = await axios.request({
          url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
          method: "GET",
          headers: {
            orgid: orgId, // Org ID
            apikey: apiKey, // API KEy
            connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          },
        });
        setSettings(getAllModulesData.data.data);
        console.log({ getAllModulesData });

        const testConnUrl = await axios.request({
          url: `${process.env.REACT_APP_DBURL}/verify`,
          method: "POST",
          headers: {
            orgid: orgId, // Org ID
            apikey: apiKey, // API KEy
            connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          },
          data: {
            Connection_Url: connectionUrl,
          },
        });

        if (testConnUrl.data) {
          setSeverity("success");
          setMessage("Connection Url tested successfully!");
          setValidConnectionUrl(true);
          handleConnectionUrl(connectionUrl);
          handleClickSnack();
        } else {
          setValidConnectionUrl(false);
          handleConnectionUrl(null);
          return;
        }
      } catch (err) {
        setMessage(`${err.message}. Connection URL is not correct.`);
        setSeverity("error");
        handleClickSnack();
      } finally {
        setValue("Connection_Url", connectionUrl);

        const regex = /\/\/(.+):(.+)@(.*?)(?::(\d+))?\/([\w|-]+)\??(ssl=.+)?/;
        const connectionString = connectionUrl;

        const match = connectionString.match(regex);

        if (!match) {
          setValue("username", null);
          setValue("password", null);
          setValue("hostname", null);
          setValue("database", null);
          setValue("sslOptions", null);
          return;
        }

        const [, username, password, hostname, port, database, ssl] = match;

        setValue("username", username);
        setValue("password", password);
        setValue("hostname", hostname);
        setValue("database", database);
        setValue("sslOptions", ssl?.replace("ssl=", "") || "");
        setShowBackDrop(false);
      }
    })();
  }, []);

  const onsubmit = async (data) => {
    const connectionValue = getValues("Connection_Url");

    if (connectionValue && validConnectionUrl === false) {
      setMessage("Please test your Connection Url!");
      setSeverity("error");
      handleClickSnack();
      return;
    }

    try {
      setLoader((prev) => ({ ...prev, save_conn: true }));
      //we will this endpoint to verify connection url
      const testConnUrl = await axios.request({
        url: `${process.env.REACT_APP_DBURL}/verify`,
        method: "POST",
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
        data: {
          Connection_Url: data.Connection_Url,
        },
      });

      //filter data based on settingType=ConnectionSchema
      //if there is found any data we will grab the settingId from there
      //if there is settingId we will update the connection url otherwise we create new connection url
      // const response = await axios.request({
      //   url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings?moduleApiName=ConnectionSchema`,
      //   method: "GET",
      //   headers: {
      //     orgid: orgId, // Org ID
      //     apikey: apiKey, // API KEy
      //     connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
      //   },
      // });
      // const settingId = response.data.data?.[0]?.setting_id || null;

      setValidConnectionUrl(true);

      setMessage("Connection Url tested successfully!");
      setSeverity("success");

      handleClickSnack();

      handleConnectionUrl(data.Connection_Url);

      // db url to org variable
      const dbConnData = {
        apiname:
          process.env.REACT_APP_EXTENSION_IDENTIFIER + "__DB_Connection_URL",
        value: data.Connection_Url,
      };

      const resp = await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", dbConnData);

      clearErrors("Connection_Url");

      setDisabledTabs((prev) => {
        return {
          ...prev,
          settingsTab: false,
        };
      });

      setTimeout(() => {
        handleCloseSnack();
        setTabNumber(2);
      }, 700);
    } catch (err) {
      setValidConnectionUrl(false);
      setError("Connection_Url", {
        type: "custom",
        message: "Something Went Wrong",
      });
      setMessage("Connection Url is wrong!");
      setSeverity("error");
      handleClickSnack();
      handleConnectionUrl(null);
    } finally {
      setLoader((prev) => ({ ...prev, save_conn: false }));
      setOpenSnack(false);
    }
  };

  console.log({ settings });
  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
        }}
      >
        <Box sx={{ width: "60%", height: "100%" }}>
          <Typography sx={{ fontSize: "24px", p: "22px 0 0 40px", mb: "1rem" }}>
            DB Connection
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit(onsubmit)}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              alignItems: "start",
              p: "0 40px 1rem",
            }}
          >
            {/* username */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: "8px 16px",
              }}
            >
              <Typography sx={{ width: "30%", mt: "-1rem" }}>
                UserName
              </Typography>

              <Controller
                control={control}
                name="username"
                rules={{ required: true }}
                disabled={settings?.length > 0}
                render={({ field, fieldState }) => (
                  <TextField
                    error={!!fieldState.error}
                    helperText={fieldState?.error?.message}
                    size="small"
                    id="username"
                    variant="outlined"
                    fullWidth
                    {...field}
                    sx={{ mb: "1rem" }}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setValidConnectionUrl(false);
                      const username = e.target.value;
                      const password = getValues("password");
                      const port = getValues("port");
                      const hostname = getValues("hostname");
                      const database = getValues("database");
                      const sslOptions = getValues("sslOptions");
                      const connection_url = `mysql://${username}:${password}@${hostname}${
                        port && `:${port}`
                      }/${database}${sslOptions && `?ssl=${sslOptions}`}`;

                      setValue("Connection_Url", connection_url);
                    }}
                  />
                )}
              />
            </Box>
            {/* password */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: "8px 16px",
              }}
            >
              <Typography sx={{ width: "30%", mt: "-1rem" }}>
                Password
              </Typography>

              <Controller
                control={control}
                name="password"
                rules={{ required: true }}
                disabled={settings?.length > 0}
                render={({ field, fieldState }) => (
                  <TextField
                    error={!!fieldState.error}
                    helperText={fieldState?.error?.message}
                    size="small"
                    id="password"
                    variant="outlined"
                    fullWidth
                    {...field}
                    sx={{ mb: "1rem" }}
                    type="password"
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setValidConnectionUrl(false);
                      const password = e.target.value;
                      const username = getValues("username");
                      const port = getValues("port");
                      const hostname = getValues("hostname");
                      const database = getValues("database");
                      const sslOptions = getValues("sslOptions");
                      const connection_url = `mysql://${username}:${password}@${hostname}${
                        port && `:${port}`
                      }/${database}${sslOptions && `?ssl=${sslOptions}`}`;
                      setValue("Connection_Url", connection_url);
                    }}
                  />
                )}
              />
            </Box>

            {/* host and port */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: "8px 16px",
                mt: "16px",
                mb: "16px",
              }}
            >
              <Typography sx={{ width: "30%", mt: "-1rem" }}>Host</Typography>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Controller
                  control={control}
                  name="hostname"
                  disabled={settings?.length > 0}
                  render={({ field, fieldState }) => (
                    <TextField
                      size="small"
                      id="hostname"
                      variant="outlined"
                      error={!!fieldState.error}
                      helperText={fieldState?.error?.message}
                      {...field}
                      sx={{ width: "55%" }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setValidConnectionUrl(false);
                        const username = getValues("username");
                        const password = getValues("password");
                        const hostname = e.target.value;
                        const port = getValues("port");
                        const database = getValues("database");
                        const sslOptions = getValues("sslOptions");
                        const connection_url = `mysql://${username}:${password}@${hostname}${
                          port && `:${port}`
                        }/${database}${sslOptions && `?ssl=${sslOptions}`}`;

                        setValue("Connection_Url", connection_url);
                      }}
                    />
                  )}
                />

                <Typography sx={{ ml: "1rem" }}>Port</Typography>

                <Controller
                  control={control}
                  name="port"
                  disabled={settings?.length > 0}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      id="port"
                      variant="outlined"
                      {...field}
                      sx={{ width: "32%" }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setValidConnectionUrl(false);
                        const username = getValues("username");
                        const password = getValues("password");
                        const port = e.target.value;
                        const hostname = getValues("hostname");
                        const database = getValues("database");
                        const sslOptions = getValues("sslOptions");
                        const connection_url = `mysql://${username}:${password}@${hostname}${
                          port && `:${port}`
                        }/${database}${sslOptions && `?ssl=${sslOptions}`}`;
                        setValue("Connection_Url", connection_url);
                      }}
                    />
                  )}
                />
              </Box>
            </Box>

            {/* database */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: "8px 16px",
              }}
            >
              <Typography sx={{ width: "30%", mt: "-1rem" }}>
                Database
              </Typography>

              <Controller
                control={control}
                name="database"
                disabled={settings?.length > 0}
                rules={{ required: "This is required Field" }}
                render={({ field, fieldState }) => (
                  <>
                    <TextField
                      error={!!fieldState.error}
                      helperText={fieldState?.error?.message}
                      size="small"
                      id="database"
                      variant="outlined"
                      fullWidth
                      {...field}
                      sx={{ mb: "1rem" }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setValidConnectionUrl(false);
                        const database = e.target.value;
                        const username = getValues("username");
                        const port = getValues("port");
                        const hostname = getValues("hostname");
                        const password = getValues("password");
                        const sslOptions = getValues("sslOptions");
                        const connection_url = `mysql://${username}:${password}@${hostname}${
                          port && `:${port}`
                        }/${database}${sslOptions && `?ssl=${sslOptions}`}`;
                        setValue("Connection_Url", connection_url);
                      }}
                    />
                  </>
                )}
              />
            </Box>

            {/* database */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: "8px 16px",
              }}
            >
              <Typography sx={{ width: "30%", mt: "-1rem" }}>
                SSl options
              </Typography>

              <Controller
                control={control}
                name="sslOptions"
                disabled={settings?.length > 0}
                render={({ field }) => (
                  <TextField
                    size="small"
                    id="database"
                    variant="outlined"
                    fullWidth
                    {...field}
                    sx={{ mb: "1rem" }}
                    onChange={(e) => {
                      field.onChange(e.target.value || "");
                      setValidConnectionUrl(false);
                      const sslOptions = e.target.value;
                      const username = getValues("username");
                      const port = getValues("port");
                      const hostname = getValues("hostname");
                      const password = getValues("password");
                      const database = getValues("database");
                      const connection_url = `mysql://${username}:${password}@${hostname}${
                        port && `:${port}`
                      }/${database}${sslOptions && `?ssl=${sslOptions || ""}`}`;
                      setValue("Connection_Url", connection_url);
                    }}
                  />
                )}
              />
            </Box>

            {/* connection url */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: "8px 16px",
              }}
            >
              <Typography sx={{ width: "30%", mt: "-1rem" }}>
                Connection Url
              </Typography>

              <Controller
                control={control}
                name="Connection_Url"
                disabled={settings?.length > 0}
                render={({ field, fieldState }) => (
                  <>
                    <TextField
                      size="small"
                      id="connection_url"
                      variant="outlined"
                      value={field.value}
                      fullWidth
                      sx={{ mb: "1rem" }}
                      error={errors["Connection_Url"]}
                      helperText={errors?.["Connection_Url"]?.message}
                      disabled={settings?.length > 0}
                      onChange={(e) => {
                        const connectionUrl = e.target.value;
                        field.onChange(e.target.value);
                        setValidConnectionUrl(false);

                        const regex =
                          /\/\/(.+):(.+)@(.*?)(?::(\d+))?\/([\w|-]+)\??(ssl=.+)?/;
                        const connectionString = e.target.value;

                        const match = connectionString.match(regex);

                        if (match) {
                          const [
                            ,
                            username,
                            password,
                            hostname,
                            port,
                            database,
                            ssl,
                          ] = match;

                          setValue("port", port);
                          setValue("username", username);
                          setValue("password", password);
                          setValue("hostname", hostname);
                          setValue("database", database);
                          setValue(
                            "sslOptions",
                            ssl?.replace("ssl=", "") || null
                          );
                        } else {
                          setValue("username", null);
                          setValue("password", null);
                          setValue("hostname", null);
                          setValue("database", null);
                          setValue("sslOptions", null);
                        }
                      }}
                    />
                  </>
                )}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                p: "8px 16px",
                gap: "1rem",
              }}
            >
              <LoadingButton
                loading={loader.test_conn}
                variant="outlined"
                sx={{
                  backgroundColor: "transparent",
                  boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                  border: "1px solid #B27310",
                  "&:hover": {
                    backgroundColor: "rgba(255, 165, 24, 0.04)",
                    border: "1px solid #B27310",
                  },
                  color: "#B27310",
                  p: "8px 22px",
                }}
                onClick={async () => {
                  try {
                    const connectionValue = getValues("Connection_Url");
                    setLoader((prev) => ({ ...prev, test_conn: true }));
                    //we will this endpoint to verify connection url
                    const testConnUrl = await axios.request({
                      url: `${process.env.REACT_APP_DBURL}/verify`,
                      method: "POST",
                      headers: {
                        orgid: orgId, // Org ID
                        apikey: apiKey, // API KEy
                        connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
                      },
                      data: {
                        Connection_Url: connectionValue,
                      },
                    });

                    if (testConnUrl.data) {
                      clearErrors("Connection_Url");
                      setValidConnectionUrl(true);
                      setMessage("Connection Url tested successfully!");
                      setSeverity("success");
                      handleClickSnack();
                    }
                  } catch (err) {
                    setValidConnectionUrl(false);
                    setError("Connection_Url", {
                      type: "custom",
                      message: "Connection Url is wrong!",
                    });
                    setMessage("Connection Url is wrong!");
                    setSeverity("error");
                    handleConnectionUrl(null);
                    handleClickSnack();
                  } finally {
                    setLoader((prev) => ({ ...prev, test_conn: false }));
                  }
                }}
              >
                Test Connection
              </LoadingButton>

              <LoadingButton
                type="submit"
                loading={loader.save_conn}
                variant="contained"
                disabled={settings?.length > 0}
                sx={{
                  backgroundColor: "#B27310",
                  border: "1px solid #B27310",
                  boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                  "&:hover": {
                    backgroundColor: "#B27310",
                  },
                  p: "8px 22px",
                }}
              >
                Save Connection
              </LoadingButton>
            </Box>

            <CustomSnackbar
              open={openSnack}
              handleClose={handleCloseSnack}
              message={message}
              severity={severity}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "40%",
            height: "100%",
            backgroundColor: "rgba(83, 130, 161, 0.04)",
            px: 2,
            py: 4.5,
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              mb: "1rem",
            }}
          >
            To facilitate a secure and efficient connection to your database, we
            require certain essential credentials from your end. Please provide
            the following information at your earliest convenience:
          </Typography>

          <Typography>
            <span style={{ fontWeight: "bold" }}> Host Name:</span> The name or
            address of your database server.
          </Typography>
          <Typography>
            <span style={{ fontWeight: "bold" }}>Port:</span> The port number
            (Default port for MySql is 3306)
          </Typography>
          <Typography>
            <span style={{ fontWeight: "bold" }}>Username:</span> The username
            for accessing the database.
          </Typography>
          <Typography>
            <span style={{ fontWeight: "bold" }}>Password:</span> The password
            associated with your username.
          </Typography>
          <Typography>
            <span style={{ fontWeight: "bold" }}>Database Name:</span> The
            specific name of the database you wish to access.
          </Typography>
          <Typography>OR</Typography>
          <Typography>
            <span style={{ fontWeight: "bold" }}>Connection URL:</span> The URL
            used for establishing a connection to your database.
          </Typography>
          <Typography sx={{ mt: 3 }}>
            For enhanced security and to ensure uninterrupted connectivity, it's
            crucial to whitelist
            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
              {" "}
              IP address %%%%
            </span>{" "}
            in your database firewall settings / Remote MySQL in CPanel. This is
            a vital step for a secure and reliable connection.
          </Typography>
          <Box mt={2}>
            <Alert severity="warning">
              If you want to change the <strong>Connection Url</strong>, you
              must need to delete all the modules from the{" "}
              <strong>SETTINGS</strong> tab.
            </Alert>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default DBConnection;
