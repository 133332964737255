import React, { useState, useEffect } from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import CustomDialog from "../Components/CustomDialog";
import CustomSnackbar from "../Components/CustomSnackbar";
import CustomTable from "../Components/CustomTable";

const ZOHO = window.ZOHO;

const Index = () => {
  const [finalSaveLoading, setFinalSaveLoading] = useState(false);
  const [moduleApiName, setModuleApiName] = useState(null);
  const [moduleInfo, setModuleInfo] = useState(null);
  const [moduleFields, setModuleFields] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentModuleFields, setCurrentModuleFields] = useState(null);
  const [currentDatabaseFields, setCurrentDatabaseFields] = useState([]);
  const [syncDuration, setSyncDuration] = useState({
    label: "24 Hours",
    total_in_minutes: 1440,
  });
  const [deleteBackupData, setDeleteBackupData] = useState(true);
  const [pageMode, setPageMode] = useState("");
  const [selectedModule, setSelectedModule] = useState(null);
  const [moduleDetails, setModuleDetails] = useState("MySQL Sync Settings");
  const [showBackdrop, setShowBackDrop] = useState(false);
  const [settingId, setModuleSettingId] = useState(null);
  const [dbConnectionUrl, setDbConnectionUrl] = useState(null);

  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [zohoApiKey, setZohoApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();

  //snackbar state and functions
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState(""); // success or error

  const syncDurations = [
    { label: "10 Mins", total_in_minutes: 10 },
    { label: "15 Mins", total_in_minutes: 15 },
    { label: "20 Mins", total_in_minutes: 20 },
    { label: "30 Mins", total_in_minutes: 30 },
    { label: "60 Mins", total_in_minutes: 60 },
    { label: "2 Hours", total_in_minutes: 120 },
    { label: "3 Hours", total_in_minutes: 180 },
    { label: "4 Hours", total_in_minutes: 240 },
    { label: "6 Hours", total_in_minutes: 360 },
    { label: "12 Hours", total_in_minutes: 720 },
    { label: "24 Hours", total_in_minutes: 1440 },
  ];

  const fetchModuleDataFromDb = async (api_name) => {
    const getModuleData = await axios.request({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings?moduleApiName=${api_name}`,
      method: "GET",
      headers: {
        orgid: orgId, // Org ID
        apikey: zohoApiKey, // API KEy
        connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
      },
    });

    return getModuleData.data.data;
  };

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        setModuleApiName(data.module);
        const orgData = {
          apiKeys: [
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__API_KEY",
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Organization_ID",
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__ZAPI_Key",
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL",
          ],
        };
        const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);

        setDataCenterUrl(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL"
          ]?.value
            ? orgVariables?.Success?.Content?.[
                process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL"
              ]?.value != "null"
              ? orgVariables?.Success?.Content?.[
                  process.env.REACT_APP_EXTENSION_IDENTIFIER +
                    "__Data_Center_URL"
                ]?.value
              : dataCenterUrl
            : dataCenterUrl
        );
        setZohoApiKey(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__API_KEY"
          ]?.value
        );
        setOrgId(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Organization_ID"
          ]?.value
        );
        setZapiKey(
          orgVariables?.Success?.Content?.[
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__ZAPI_Key"
          ]?.value
        );

        /**
         * Business Logic
         */
        setZohoLoaded(true);
      });

      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);
  useEffect(() => {
    if (zohoLoaded) {
      /**
       * Add Business Logic
       */
      try {
        (async () => {
          //check db connection is exist or not
          // const getDbConnection = await axios.request({
          //   url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings?moduleApiName=ConnectionSchema`,
          //   method: "GET",
          //   headers: {
          //     orgid: orgId, // Org ID
          //     apikey: zohoApiKey, // API KEy
          //     connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          //   },
          // });
          // if (!getDbConnection.data.data) {
          // setDbConnectionUrl(false);
          // setLoading(false);
          // return;
          // }

          const orgData = {
            apiKeys: [
              process.env.REACT_APP_EXTENSION_IDENTIFIER +
                "__DB_Connection_URL",
            ],
          };

          const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);
          if (!orgVariables?.Success?.Content) {
            setDbConnectionUrl(false);
            setLoading(false);
            return;
          }

          const getModuleFields = await ZOHO.CRM.META.getFields({
            Entity: moduleApiName,
          });
          const moduleFields = getModuleFields.fields.flatMap((field) => {
            if (
              field.data_type === "subform" ||
              field.data_type === "multiselectlookup"
            ) {
              return [];
            }

            return [field];
          });

          const data = await fetchModuleDataFromDb(moduleApiName);
          const getAllModules = await ZOHO.CRM.META.getModules();
          const respectiveModuleData = getAllModules.modules?.find((module) => {
            if (module.api_name === moduleApiName) return true;
          });

          setDbConnectionUrl(orgVariables?.Success?.Content);
          setModuleInfo(respectiveModuleData);
          setModuleFields(getModuleFields.fields);

          if (!data) {
            setCurrentModuleFields(moduleFields);
            setLoading(false);
            return;
          }

          const settingId = data[0].setting_id;
          const settingSchema = JSON.parse(data[0].setting_schema);
          const selectedFields = {};
          const moduleFieldsAgainstApiKey = {};

          // const responseFetchModuleFields = getModuleFields.fields;

          settingSchema?.database_fields.forEach(
            (field) =>
              (selectedFields[field.api_name + "__" + field.data_type] = field)
          );

          const filteredModuleFields = [];

          moduleFields.flatMap((field) => {
            //push all those fields to filteredModuleFields that are not selected
            if (!selectedFields[field.api_name + "__" + field.data_type]) {
              filteredModuleFields.push({
                api_name: field.api_name,
                field_label: field.field_label,
                data_type: field.data_type,
                field_exist_in_zoho: true,
              });
            }

            //add field to moduleFieldsAgainstApiKey
            moduleFieldsAgainstApiKey[field.api_name + "__" + field.data_type] =
              {
                api_name: field.api_name,
                field_label: field.field_label,
                data_type: field.data_type,
              };
          });

          const filteredCurrentDatabaseFields =
            settingSchema.database_fields.map((field, index) => {
              // if field exists in the moduleFieldsAgainstApiKey that means this field exist in that module i mean exist in the zoho
              // else we will add a extra parameter to track the field
              if (
                moduleFieldsAgainstApiKey[
                  field.api_name + "__" + field.data_type
                ]
              ) {
                return { ...field, field_exist_in_zoho: true };
              } else {
                return { ...field, field_exist_in_zoho: false };
              }
            });
          setModuleSettingId(settingId);
          setSyncDuration(settingSchema?.syncDuration);
          setDeleteBackupData(settingSchema?.deleteBackupData);
          setCurrentDatabaseFields(filteredCurrentDatabaseFields);
          setCurrentModuleFields(filteredModuleFields);
          setModuleDetails(settingSchema?.moduleDetails);
          setLoading(false);

          if (!settingId) {
            alert("Setting id not found.");
            return;
          }
        })();
      } catch (err) {
        setLoading(false);
      } finally {
      }
    }
  }, [zohoLoaded]);

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSave = async () => {
    setShowBackDrop(true);

    if (pageMode === "addNewMode") {
      //save settings to db
      const saveModuleToDb = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
        method: "POST",
        data: {
          moduleApiName: moduleInfo.api_name,
          settingSchema: {
            moduleInfo: {
              api_name: moduleInfo.api_name,
              module_name: moduleInfo.plural_label,
            },
            moduleDetails: moduleDetails,
            database_fields: currentDatabaseFields,
            syncDuration: syncDuration,
            deleteBackupData: deleteBackupData,
          },
        },
        headers: {
          orgid: orgId, // Org ID
          apikey: zohoApiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });

      const setting_id = saveModuleToDb.data.data.setting_id;

      if (saveModuleToDb.data.data) {
        //sync table to mysql db
        const addTable = await axios.request({
          url: `${process.env.REACT_APP_DBURL}/setting/${moduleInfo.api_name}`,
          method: "POST",
          data: {},
          headers: {
            orgid: orgId, // Org ID
            apikey: zohoApiKey, // API KEy
            connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
            connectionUrl: dbConnectionUrl,
          },
        });
        if (addTable.data.error) {
          setMessage(addTable.data.error);
          setSeverity("error");
          handleClickSnack();
          setShowBackDrop(false);

          // Delete Module data from db (our database)
          const deleteData = await axios.request({
            url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings/${setting_id}`,
            method: "DELETE",
            headers: {
              orgid: orgId, // Org ID
              apikey: zohoApiKey, // API KEy
              connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
            },
          });
          return;
        }

        const getCron = await axios.request({
          url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
          method: "GET",
          headers: {
            orgid: orgId, // Org ID
            apikey: zohoApiKey, // API KEy
            connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          },
        });

        //find cron for that module
        const findCronModule = getCron.data.data.find(
          (module) =>
            module.req_body.moduleName === moduleInfo.api_name &&
            module.repeat_at_min !== 1
        );

        if (findCronModule) {
          //delete cron for that module
          const deleteCron = await axios.request({
            url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs/${findCronModule.setting_id}`,
            method: "DELETE",
            headers: {
              orgid: orgId, // Org ID
              apikey: zohoApiKey, // API KEy
              connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
            },
          });
        }

        //create cron
        const createCron = await axios.request({
          url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
          method: "POST",
          data: {
            reqUrl:
              dataCenterUrl +
              "/crm/v2/functions/easymysqlsync__syncmoduledata/actions/execute?auth_type=apikey&zapikey=" +
              zapiKey,
            reqHeaders: {},
            reqParams: {},
            reqBody: {
              moduleName: moduleInfo.api_name, // Module Name
              pageNo: 0, // Always 0
            },
            reqType: "GET",
            executeIn: syncDuration.total_in_minutes, // Sync selected in the Settings in Mins
          },
          headers: {
            orgid: orgId, // Org ID
            apikey: zohoApiKey, // API KEy
            connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          },
        });

        setModuleSettingId(setting_id);
      }
    } else {
      //get setting id
      //filter data based on settingType=ConnectionSchema
      // const response = await axios.request({
      //   url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings?moduleApiName=${moduleInfo.api_name}`,
      //   method: "GET",
      //   headers: {
      //     orgid: orgId, // Org ID
      //     apikey: zohoApiKey, // API KEy
      //     connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
      //   },
      // });

      // const settingId = response.data.data[0].setting_id;

      // if (!settingId) {
      //   setMessage("Setting id not found");
      //   setSeverity("error");
      //   handleClickSnack();
      //   setShowBackDrop(false);
      //   return;
      // }

      // edit settings to db
      const updateModuleData = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
        method: "POST",
        data: {
          moduleApiName: moduleInfo.api_name,
          settingId,
          settingSchema: {
            moduleInfo: {
              api_name: moduleInfo.api_name,
              module_name: moduleInfo.module_name,
            },
            moduleDetails: "MySQL Sync Settings",
            database_fields: currentDatabaseFields,
            syncDuration: syncDuration,
            deleteBackupData: deleteBackupData,
          },
        },
        headers: {
          orgid: orgId, // Org ID
          apikey: zohoApiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });

      //sync table to mysql db
      const editTable = await axios.request({
        url: `${process.env.REACT_APP_DBURL}/setting/${moduleInfo.api_name}`,
        method: "POST",
        data: {},
        headers: {
          orgid: orgId, // Org ID
          apikey: zohoApiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          connectionUrl: dbConnectionUrl,
        },
      });
      if (editTable.data.error) {
        setMessage(editTable.data.error);
        setSeverity("error");
        handleClickSnack();
        setShowBackDrop(false);
        return;
      }

      // get crons for all modules
      const getCron = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
        method: "GET",
        headers: {
          orgid: orgId, // Org ID
          apikey: zohoApiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });

      //find cron for that module
      const findCronModule = getCron.data.data.find(
        (module) =>
          module.req_body.moduleName === moduleInfo.api_name &&
          module.repeat_at_min !== 1
      );

      //update cron info for that module
      const updateCron = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
        method: "PUT",
        data: {
          settingId: findCronModule.setting_id,
          executeIn: syncDuration.total_in_minutes, // Sync selected in the Settings in Mins
        },
        headers: {
          orgid: orgId, // Org ID
          apikey: zohoApiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });
    }
    setShowBackDrop(false);
    setOpen(false);
    setPageMode("");
  };

  const handleSubmit = async () => {
    setFinalSaveLoading(true);
    // const insertedData = await axios.request({
    //   url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
    //   method: "POST",
    //   data: {
    //     settingId: seletedSetting?.setting_id || "",
    //     settingSchema: dataMap,
    //     moduleApiName: defaultModule,
    //     settingType: "workflow",
    //   },
    //   headers: {
    //     orgid: orgId,
    //     apikey: apiKey,
    //     datacenterurl: dataCenterUrl,
    //     connname: `${process.env.REACT_APP_EXTENSION_IDENTIFIER}`,
    //   },
    // });

    // const settingId = insertedData?.data?.data?.setting_id || "";

    if (settingId !== "") {
      setMessage("Successfull");
      setSeverity("success");
      handleClickSnack();
      setFinalSaveLoading(false);

      await ZOHO.CRM.ACTION.setConfig({
        settingId: settingId,
        orgid: orgId,
        datacenterurl: dataCenterUrl,
        connname: `${process.env.REACT_APP_EXTENSION_IDENTIFIER}`,
      });
    } else {
      setMessage("Please create setting first!!!");
      setSeverity("error");
      handleClickSnack();
      setFinalSaveLoading(false);
    }
  };

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (!dbConnectionUrl)
    return <h1>Please go to settings and set the database connection.</h1>;

  if (!settingId && !open) {
    return (
      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6" mb={1}>
          No settings found for {moduleInfo.module_name} module.
        </Typography>
        <Button
          variant="contained"
          size="medium"
          onClick={() => {
            setPageMode("addNewMode");
            setOpen(true);
          }}
        >
          Add Settings
        </Button>
      </Box>
    );
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomSnackbar
        open={openSnack}
        handleClose={handleCloseSnack}
        message={message}
        severity={severity}
      />

      {/* <button
        onClick={async () => {
          //get crons for all modules
          const getCron = await axios.request({
            url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
            method: "GET",
            headers: {
              orgid: orgId, // Org ID
              apikey: zohoApiKey, // API KEy
              connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
            },
          });

          
        }}
      >
        Get Cron
      </button>
    */}

      <Grid container spacing={2} p={2}>
        <Grid item xs={3.5}>
          <Box
            p={2}
            sx={{
              borderRadius: "4px",
              borderTop: "2px solid #759BB3",
              boxShadow:
                "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)",
              "&:hover .button-box": {
                visibility: "visible",
              },
            }}
          >
            <Typography sx={{ fontSize: "24px" }}>
              {moduleInfo?.plural_label}
            </Typography>

            <Typography sx={{ fontSize: "14px", mt: 2 }}>
              {moduleDetails}
            </Typography>
            <Box mt={2} sx={{ display: "flex", gap: 1 }}>
              <Button
                size="small"
                variant="outlined"
                onClick={async () => {
                  setShowBackDrop(true);
                  const data = await fetchModuleDataFromDb(moduleApiName);

                  const settingId = data[0].setting_id;
                  const settingSchema = JSON.parse(data[0].setting_schema);
                  const selectedFields = {};
                  const moduleFieldsAgainstApiKey = {};

                  settingSchema?.database_fields.forEach(
                    (field) =>
                      (selectedFields[field.api_name + "__" + field.data_type] =
                        field)
                  );
                  const filteredModuleFields = [];

                  moduleFields.flatMap((field) => {
                    //push all those fields to filteredModuleFields that are not selected
                    if (
                      !selectedFields[field.api_name + "__" + field.data_type]
                    ) {
                      filteredModuleFields.push({
                        api_name: field.api_name,
                        field_label: field.field_label,
                        data_type: field.data_type,
                        field_exist_in_zoho: true,
                      });
                    }

                    //add field to moduleFieldsAgainstApiKey
                    moduleFieldsAgainstApiKey[
                      field.api_name + "__" + field.data_type
                    ] = {
                      api_name: field.api_name,
                      field_label: field.field_label,
                      data_type: field.data_type,
                    };
                  });

                  const filteredCurrentDatabaseFields =
                    settingSchema.database_fields.map((field, index) => {
                      // if field exists in the moduleFieldsAgainstApiKey that means this field exist in that module i mean exist in the zoho
                      // else we will add a extra parameter to track the field
                      if (
                        moduleFieldsAgainstApiKey[
                          field.api_name + "__" + field.data_type
                        ]
                      ) {
                        return { ...field, field_exist_in_zoho: true };
                      } else {
                        return { ...field, field_exist_in_zoho: false };
                      }
                    });
                  setPageMode("editMode");
                  setModuleSettingId(settingId);
                  setSyncDuration(settingSchema?.syncDuration);
                  setDeleteBackupData(settingSchema?.deleteBackupData);
                  setCurrentDatabaseFields(filteredCurrentDatabaseFields);
                  setCurrentModuleFields(filteredModuleFields);
                  setModuleDetails(settingSchema?.moduleDetails);
                  setShowBackDrop(false);
                  setOpen(true);
                }}
              >
                Edit
              </Button>
              {/* <Button
                size="small"
                variant="contained"
                onClick={async () => {
                  setShowBackDrop(true);
                  
                  // Delete Module data from db (our database)
                  const deleteData = await axios.request({
                    url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings/${settingId}`,
                    method: "DELETE",
                    headers: {
                      orgid: orgId, // Org ID
                      apikey: zohoApiKey, // API KEy
                      connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
                    },
                  });
                  
                  if (deleteData.data.error) {
                    setMessage(deleteData.data.error);
                    setSeverity("error");
                    handleClickSnack();
                    setShowBackDrop(false);
                    return;
                  }
                  //delete table from mysql
                  const deleteTable = await axios.request({
                    url: `${process.env.REACT_APP_DBURL}/setting/${moduleApiName}`,
                    method: "DELETE",
                    headers: {
                      orgid: orgId, // Org ID
                      apikey: zohoApiKey, // API KEy
                      connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
                      connectionUrl: dbConnectionUrl,
                    },
                  });

                  //get crons for all modules
                  const getCron = await axios.request({
                    url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
                    method: "GET",
                    headers: {
                      orgid: orgId, // Org ID
                      apikey: zohoApiKey, // API KEy
                      connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
                    },
                  });
                  

                  //find cron for that module
                  const findCronModule = getCron.data.data.find(
                    (cron) => cron.req_body.moduleName === moduleApiName
                  );

                  

                  //delete cron for that module
                  const deleteCron = await axios.request({
                    url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs/${findCronModule.setting_id}`,
                    method: "DELETE",
                    headers: {
                      orgid: orgId, // Org ID
                      apikey: zohoApiKey, // API KEy
                      connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
                    },
                  });
                  setModuleSettingId(null);
                  setShowBackDrop(false);
                }}
              >
                Delete
              </Button> */}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8.5}>
          <CustomTable
            headers={["Selected Fields"]}
            rows={currentDatabaseFields}
            id="field_label"
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mr: 2 }}>
        <Button
          size="small"
          onClick={handleSubmit}
          sx={{
            px: 2,
            width: 120,
          }}
          variant="contained"
        >
          {finalSaveLoading ? (
            <CircularProgress size={21} color="inherit" />
          ) : (
            "Save"
          )}
        </Button>
      </Box>

      {pageMode === "editMode" ? (
        <CustomDialog
          selectedModule={selectedModule}
          mode={pageMode}
          customAction={true}
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
          currentModuleFields={currentModuleFields}
          setCurrentModuleFields={setCurrentModuleFields}
          currentDatabaseFields={currentDatabaseFields}
          setCurrentDatabaseFields={setCurrentDatabaseFields}
          syncDuration={syncDuration}
          setSyncDuration={setSyncDuration}
          deleteBackupData={deleteBackupData}
          setDeleteBackupData={setDeleteBackupData}
          syncDurations={syncDurations}
          handleSave={handleSave}
          syncAllFields={() => {
            setCurrentDatabaseFields(moduleFields);
            setTimeout(() => {
              setCurrentModuleFields([]);
            }, 100);
          }}
          unSelectAllFields={() => {
            setCurrentModuleFields(moduleFields);
            setCurrentDatabaseFields([]);
          }}
          handleDeleteField={(value) => {
            const filteredCurrentDatabaseFields = currentDatabaseFields.filter(
              (field) => field.api_name !== value.api_name
            );

            setCurrentDatabaseFields([...filteredCurrentDatabaseFields]);
          }}
        />
      ) : (
        <CustomDialog
          open={open}
          mode={pageMode}
          customAction={true}
          selectedModule={selectedModule}
          currentModuleFields={currentModuleFields}
          handleClose={() => {
            setOpen(false);
          }}
          syncAllFields={() => {
            setCurrentDatabaseFields([...moduleFields]);
            setTimeout(() => {
              setCurrentModuleFields([]);
            }, 100);
          }}
          unSelectAllFields={() => {
            setCurrentModuleFields([...moduleFields]);
            setCurrentDatabaseFields([]);
          }}
          syncDurations={syncDurations}
          setCurrentModuleFields={setCurrentModuleFields}
          currentDatabaseFields={currentDatabaseFields}
          setCurrentDatabaseFields={setCurrentDatabaseFields}
          syncDuration={syncDuration}
          setSyncDuration={setSyncDuration}
          deleteBackupData={deleteBackupData}
          setDeleteBackupData={setDeleteBackupData}
          handleSave={handleSave}
        />
      )}
    </>
  );
};

export default Index;
